<template>
  <b-container fluid class="px-0">
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ lang("t_show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ lang("t_entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="lang('t_search')"
              />
              <b-button class="mr-1" variant="success" @click="onexport">
                <span class="text-nowrap">{{ lang("t_export") }}</span>
              </b-button>
              <b-button
                class="mr-1"
                variant="success"
                @click="
                  modal_excel = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_importRecords") }}</span>
              </b-button>
              <b-button
                variant="primary"
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
              >
                <span class="text-nowrap">{{ lang("t_addRecord") }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        style="min-height: 300px"
        ref="refUserListTable"
        class="position-relative"
        :items="records"
        responsive
        fixed
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="lang('t_noRecord')"
        :sort-desc.sync="isSortDirDesc"
        :filter="searchQuery"
      >
        <template #cell(profiles)="data">
          {{ getProfileDisplayNames(data.item.profiles) }}
        </template>
        <template #cell(projects)="data">
          {{ getProjectsDisplayNames(data.item.projects) }}
        </template>
        <template #cell(status)="data">
          <feather-icon
            :icon="data.item.status != 'passive' ? 'CheckIcon' : 'XIcon'"
            size="12"
            class="align-middle text-body"
          />
        </template>
        <template #cell(is_visible_in_live_watch)="data">
          <!-- {{ data.item.is_visible_in_live_watch }} -->

          <feather-icon
            :icon="data.item.is_visible_in_live_watch ? 'CheckIcon' : 'XIcon'"
            size="12"
            class="align-middle text-body"
          />
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateSelected(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmText(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ lang("t_delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ lang("t_showing") }} {{ records.length }}
              {{ lang("t_entries") }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_record"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <b-form-group label-for="type">
            <b-row>
              <b-col>
                <b-form-radio
                  v-model="currentData.type"
                  value="user"
                  class="custom-control-primary"
                >
                  {{ lang("t_user") }}
                </b-form-radio>
              </b-col>
              <b-col>
                <b-form-radio
                  v-model="currentData.type"
                  value="extension"
                  class="custom-control-primary"
                >
                  {{ lang("t_extension") }}
                </b-form-radio>
              </b-col>
            </b-row>
          </b-form-group>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group :label="lang('t_name')" label-for="name">
              <b-form-input
                id="name"
                v-model="currentData.name"
                :state="getValidationState(validationContext)"
                trim
                @input="setUsername"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[1] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Surname -->
          <validation-provider
            #default="validationContext"
            name="Surname"
            rules="required"
          >
            <b-form-group :label="lang('t_surname')" label-for="surname">
              <b-form-input
                id="surname"
                v-model="currentData.surname"
                :state="getValidationState(validationContext)"
                trim
                @input="setUsername"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Username -->
          <validation-provider
            #default="validationContext"
            name="Username"
            rules="required"
          >
            <b-form-group :label="lang('t_userName')" label-for="username">
              <b-form-input
                :disabled="isEdit"
                id="username"
                v-model="currentData.username"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Password -->
          <validation-provider
            #default="validationContext"
            name="Password"
            vid="Password"
            rules="required"
          >
            <b-form-group :label="lang('t_password')" label-for="password">
              <b-form-input
                id="password"
                v-model="currentData.password"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="exten"
            rules="required"
          >
            <b-form-group
              :label="lang('t_extension')"
              label-for="exten"
              :state="getValidationState(validationContext)"
            >
              <v-select
                :disabled="isEdit"
                id="extens"
                v-model="currentData.exten"
                :options="extens"
                :placeholder="lang('t_extension')"
                :reduce="(val) => val.label"
                value="value"
                label="label"
                :selectable="(option) => !option.disabled"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="email">
            <b-form-group
              :label="lang('t_email')"
              label-for="email"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="email"
                v-model="currentData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="validationContext" name="description">
            <b-form-group
              :label="lang('t_description')"
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="description"
                v-model="currentData.description"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- User Role -->
          <validation-provider
            v-if="currentData.type == 'user'"
            #default="validationContext"
            name="project"
            rules="required"
          >
            <b-form-group
              :label="lang('t_project')"
              label-for="project"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.projects"
                :options="projects"
                :placeholder="lang('t_project')"
                :reduce="(val) => val.internal_name"
                value="internal_name"
                label="display_name"
                multiple
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Plan -->
          <validation-provider
            v-if="currentData.type == 'user'"
            #default="validationContext"
            name="profile"
            rules="required"
          >
            <b-form-group
              :label="lang('t_profile')"
              label-for="profile"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.profiles"
                :options="profiles"
                :placeholder="lang('t_profile')"
                :reduce="(val) => val.internal_name"
                value="internal_name"
                label="display_name"
                multiple
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="currentData.type == 'extension'"
            #default="validationContext"
            name="router"
          >
            <b-form-group
              :label="lang('t_extensionRoutes')"
              label-for="router"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="currentData.router"
                :options="routers"
                :placeholder="lang('t_extensionRoutes')"
                :reduce="(val) => val"
                value="internal_name"
                label="display_name"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-checkbox
            v-if="currentData.type == 'user'"
            v-model="currentData.status"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
                {{ lang("t_active") }}
              </span>
            </span>
          </b-form-checkbox>

          <b-form-checkbox
            v-if="currentData.type == 'user'"
            v-model="currentData.is_visible_in_live_watch"
            class="custom-control-primary"
          >
            <span class="vs-checkbox">
              <span class="vs-checkbox--check">
                <i class="vs-icon feather icon-check" />
                {{ lang("t_is_visible_in_live_dasboard") }}
              </span>
            </span>
          </b-form-checkbox>

          <!-- Form Actions -->
          <!-- <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ lang("t_add") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ lang("t_cancel") }}
            </b-button>
          </div> -->

          <div v-if="currentData.type == 'extension'">
            <b-row
              ><b-col>
                <span>Host:</span> <span>{{ host }}</span>
              </b-col>
            </b-row>
            <b-row
              ><b-col> <span>Port:</span> <span>5090</span> </b-col>
            </b-row>
            <b-row
              ><b-col>
                <span>User:</span>
                <span>{{
                  `sip${currentData.username}${domain.replace("@", "_")}`
                }}</span>
              </b-col>
            </b-row>
            <b-row
              ><b-col>
                <span>Pass:</span> <span>{{ currentData.password }}</span>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      @ok="read_data"
      v-model="modal_excel"
      :title="lang('t_importRecords')"
      centered
    >
      <b-row align-h="end">
        <b-button
          href="template_examples/voyce_user_import.xlsx"
          :exact="true"
          size="sm"
          variant="outline-success"
          >{{ lang("t_exampleFile") }}</b-button
        >
      </b-row>
      <b-row class="mt-1">
        <b-form-file
          :browse-text="lang('t_file')"
          v-model="files"
          :placeholder="lang('t_uploadFileOnClick')"
          :drop-placeholder="lang('t_uploadFile')"
          no-drop
          required
          accept=".xlsx, .xls"
        />
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import {
  BForm,
  BContainer,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BFormFile,
  BFormRadio,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BContainer,
    BFormFile,
    BForm,
    BFormRadio,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      progress: false,
      files: {},
      host: "",
      currentData: {
        type: "user",
        name: "",
        surname: "",
        username: "",
        identifier: "",
        password: "",
        exten: "",
        email: "",
        description: "",
        projects: [],
        profiles: [],
        temp_profiles: [],
        added_profiles: [],
        removed_profiles: [],
        location: "",
        status: true,
        is_visible_in_live_watch: true,
        router: null,
      },
      modal_record_details: false,
      modal_excel: false,
      required,
      alphaNum,
      email,
      password,
      records: [],
      routers: [],
      extnes: [],
      tableColumns: [
        // { key: 'name', label: globalThis._lang('t_name'), sortable: true },
        // { key: 'surname', label: globalThis._lang('t_surname'), sortable: true },
        {
          key: "username",
          label: globalThis._lang("t_userName"),
          sortable: true,
        },
        {
          key: "exten",
          label: globalThis._lang("t_extension"),
          sortable: true,
        },
        {
          key: "projects",
          label: globalThis._lang("t_projects"),
          sortable: false,
        },
        {
          key: "profiles",
          label: globalThis._lang("t_profiles"),
          sortable: false,
        },
        { key: "status", label: globalThis._lang("t_active"), sortable: false },
        {
          key: "is_visible_in_live_watch",
          label: globalThis._lang("t_is_visible_in_live_dasboard"),
          sortable: false,
        },
        {
          key: "actions",
          label: globalThis._lang("t_actions"),
          sortable: false,
        },
      ],
    };
  },
  mounted: async function () {
    this.is_progress = true;
    this.domain = "@" + globalThis.username.split("@")[1];
    this.host = globalThis.env.MEDIA_URL;
    await this.get_profiles();
    await this.get_extens();
    await this.get_projects();
    await this.get_routers();
    await this.get_records();
    this.is_progress = false;
  },
  methods: {
    setUsername() {
      if (!this.currentData.name && !this.currentData.surname) {
        this.currentData.username = '';
        return;
      }

      const map = {
        ç: 'c', Ç: 'c',
        ğ: 'g', Ğ: 'g',
        ı: 'i', İ: 'i',
        ö: 'o', Ö: 'o',
        ş: 's', Ş: 's',
        ü: 'u', Ü: 'u',
      };
      let first_part = this.currentData.name.toLowerCase().replace(/[çÇğĞıİöÖşŞüÜ]/g, function (match) {
        return map[match];
      });
      let second_part = this.currentData.surname.toLowerCase().replace(/[çÇğĞıİöÖşŞüÜ]/g, function (match) {
        return map[match];
      });

      this.currentData.username = `${first_part.split(" ")[0]}.${second_part.split(" ")[0]}${this.domain}`;
    },
    onexport() {
      // On Click Excel download button
      let _headers = {};
      for (const header of this.tableColumns) {
        _headers[header.key] = header.label;
      }

      let _data = [];
      for (const row of this.records) {
        let obj = {};

        for (const column of Object.keys(row)) {
          let key = _headers[column] == undefined ? column : _headers[column];
          obj[key] = row[column];
        }
        _data.push(obj);
      }

      var data = this.$xlsx.utils.json_to_sheet(_data);

      // A workbook is the name given to an Excel file
      var wb = this.$xlsx.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      this.$xlsx.utils.book_append_sheet(wb, data, `user_list`); // sheetAName is name of Worksheet

      // export Excel file
      this.$xlsx.writeFile(wb, `user_list_${new Date().getTime()}.xlsx`); // name of the file is 'book.xlsx'
    },
    async read_data() {
      this.progress = true;
      const reader = new FileReader();
      reader.onload = (e) => {
        const bstr = e.target.result;
        const wb = globalThis.XLSX.read(bstr, { type: "binary" });

        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = globalThis.XLSX.utils.sheet_to_json(ws, {
          header: 1,
          raw: false,
        });
        var _data = this.xlsxToJson(data);

        for (const item of _data) {
          var _exten = this.extens.find((e) => e.disabled == false).value;
          this.extens.find((e) => e.disabled == false).disabled = true;
          this.currentData = {
            name: item["isim"],
            surname: item["soyisim"],
            username: item["kullanici_adi"],
            password: item["sifre"],
            description: item["aciklama"] ?? '',
            exten: _exten,
            projects: [],
            profiles: [],
            temp_profiles: [],
            added_profiles: [],
            removed_profiles: [],
            status: true,
          };
          console.log("Data", this.currentData);
          this.add_record();
        }
        this.progress = false;
        this.modal_excel = false;
      };
      reader.readAsBinaryString(this.files);
    },

    xlsxToJson(data) {
      var jsonData = [];
      let headers = data[0];
      let _data = data.slice(1);
      let objectHolder = [];
      for (let row of _data) {
        let _row = [];
        for (var i = 0; i < row.length; i++) {
          _row.push([headers[i], row[i]]);
        }
        jsonData.push(Object.fromEntries(_row));
      }
      return jsonData;
    },
    getProfileDisplayNames(pf) {
      let _profiles = pf;
      let text = "";
      for (const item of _profiles) {
        if (item != "base_privileges") {
          text +=
            (this.profiles.find((e) => e.internal_name == item) != undefined
              ? this.profiles.find((e) => e.internal_name == item).display_name
              : item) + ",";
        }
      }
      text = text.substr(0, text.length - 1);
      return text;
    },
    getProjectsDisplayNames(pf) {
      let _projects = pf;
      let text = "";
      for (const item of _projects) {
        if (item != "base_privileges") {
          text +=
            this.projects.find((e) => e.internal_name == item) != undefined
              ? this.projects.find((e) => e.internal_name == item)
                .display_name + ","
              : "";
        }
      }
      text = text.substr(0, text.length - 1);
      return text;
    },
    get_records: async function () {
      this.is_progress = true;
      var response = (await this.$http_in.get(`/optmgr/v1/AllUser`)).data;
      console.log("User", response);
      this.records = response;

      for (const item of response) {
        this.extens.find((e) => e.value == item.exten).disabled = true;
      }
      this.is_progress = false;
    },
    get_routers: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Router`)).data;
      this.routers = response;
    },
    get_extens: async function () {
      this.extens = [];
      for (let index = 1000; index < 5000; index++) {
        this.extens.push({
          label: index,
          value: index,
          disabled: false,
        });
      }
      // this.extens = response;
    },
    get_projects: async function () {
      var response = (await this.$http_in.get(`optmgr/v1/Project`)).data;
      this.projects = response;
    },
    get_profiles: async function () {
      var response = (await this.$http_in.get(`Auth/v1/Profiles`)).data;
      console.log("profiles", response);
      this.profiles = response;
    },
    //     name
    //     surname
    //     username
    //     password
    //     exten
    check_data() {
      if (
        this.currentData.name == "" ||
        this.currentData.surname == "" ||
        this.currentData.username == "" ||
        this.currentData.password == "" ||
        this.currentData.exten == ""
      ) {
        return true;
      }
      return false;
    },
    add_record: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }
      this.is_progress = true;
      this.currentData.added_profiles = this.currentData.profiles.filter(
        (p) => !this.currentData.temp_profiles.includes(p)
      );
      this.currentData.removed_profiles = this.currentData.temp_profiles.filter(
        (p) => !this.currentData.profiles.includes(p)
      );
      if (this.currentData.status) {
        this.currentData.status = "new";
      } else {
        this.currentData.status = "passive";
      }

      var response;
      if (!this.isEdit) {
        response = (
          await this.$http_in.post(`/optmgr/v1/User`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/User`, {
            data: JSON.stringify(this.currentData),
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          // text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          text: response.message,
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.get_records();
      this.reset_record();
    },
    delete_record: async function () {
      this.is_progress = true;
      var response = (
        await this.$http_in.delete(
          `/optmgr/v1/User/${this.currentData.identifier}`
        )
      ).data;
      this.is_progress = false;

      if (response && response.acknowledged) {
        this.$swal({
          icon: "success",
          title: this.lang("t_processSuccess"),
          text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
          confirmButtonText: this.lang("t_ok"),
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.currentData = {
        type: "user",
        name: "",
        surname: "",
        username: "",
        identifier: "",
        password: "",
        description: "",
        email: "",
        exten: this.extens.find((e) => e.disabled == false).value,
        projects: [],
        profiles: [],
        temp_profiles: [],
        added_profiles: [],
        removed_profiles: [],
        status: true,
        is_visible_in_live_watch: true,
        router: null,
      };
    },

    updateSelected(item) {
      this.currentData = {
        ...item,
        projects: item.projects,
        profiles: item.profiles,
        temp_profiles: item.profiles,
        exten: parseInt(item.exten),
        username: item.username,
        visible_in_reports: item.is_visible_in_live_watch,
        status: item.status == "passive" ? false : true,
      };
      console.log("item", item);
      this.isEdit = true;
      this.modal_record_details = true;
      // this.isAddNewUserSidebarActive = true;
    },

    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
//@import "@core/scss/base/core/colors/_palette.scss";
</style>
